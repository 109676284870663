._{
    background: #FFFFFF;
    border-radius: 8px;
    max-width: 375px;
    width: 100%;
    margin: 10px;
    position: relative;

}
.block{
    padding: 32px;
    position: relative;
}
.text1{
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0;
    text-align: left;
}
.text2{
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    text-align: left;
    padding-top: 5px;
}
.text3{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    margin-top: 20px;
}
.fon{
    background: #16181D99;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    z-index: 5;
    top: 0;
    left: 0;
}
.input{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    width: calc(100% - 24px);
    border-radius: 8px;
    gap: 8px;
    padding: 12px;
    margin-top: 8px;
    border: 1px solid #E2E4E9;
}
.button{
    width: calc(100% - 32px);
    border-radius: 8px;
    gap: 8px;
    padding-top: 12px;
    padding-right: 16px;
    padding-bottom: 12px;
    padding-left: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    color: #FFFFFF;
    background: #0B0C0E;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    cursor: pointer;
}
.text4{
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    letter-spacing: 0;
    vertical-align: middle;
    color: #80899C;
    margin-top: 20px;
}
.text5{
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    letter-spacing: 0;
    color: black;
    cursor: pointer;
}
.image{
    width: 100%;
    height: auto;
    border-radius: 8px 8px 0 0;
}
.close{
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
}
.not{
    position: fixed;
    right: 30px;
    top: 30px;
    background: #FFFFFF;
    height: 50px;
    max-width: 300px;
    min-width: 100px;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}