.wrapper {
   background: #f8f8f8;
   padding: 3px 0;
}

.container {
   display: flex;
   justify-content: space-between;
   align-items: center;
   height: 32px;
}

.newMenuWrapper {
   display: flex;
   align-items: center;
   gap: 26px;
   > div {
      font-size: 14px;
      color: #596173;
      font-weight: 600;
      letter-spacing: -0.2px;
      line-height: 20px;
      cursor: pointer;
      transition: all .3s ease;
      &:hover {
         color: #0B0C0E;
      }
   }
}
.list{
   position: absolute;
   background: #f8f8f8;
   padding: 10px 10px;
   margin: 0 0 0 -10px;
   opacity: 0;
   border-radius: 4px;
   transition: all .3s ease;
   z-index: -100;
}
.menuItem:hover > .list{
   display: block;
   color: #596173;
   opacity: 1;
   z-index: 10;
}
.listItem{
   transition: all .3s ease;
}
.listItem:hover{
   color: #0B0C0E;
}

@media screen and (max-width: 1280px) {
   .wrapper {
      display: none;
   }
}
