._{
    width: 90dvw;
    max-width: 1280px;
    margin: 60px auto 0 auto;
}
.sliderWrapper {
    display: flex;
    margin: 0 0 0 auto;
    width: 100%;
    position: relative;
}
.image{
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;
}
.imageBlock{
    border-radius: 16px;
    overflow: hidden;
}
.imageBlock3{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.slider {
    width: 100%;
    position: relative;
    border-radius: 16px;
    > div {
        &:nth-child(2) {
            overflow: hidden;
            border-radius: 16px;
            height: 326px;
            > div {
                height: 326px;
                > div {
                    > div {
                        > a {
                            > img {
                                border-radius: 16px;
                                height: 326px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.slider2{
    width: 100%;
    position: relative;
    border-radius: 16px;
    > div {
        &:nth-child(2) {
            height: 200px;
            > div {
                height: 170px;

            }
        }
    }
}
.imageBlock2{
    height: 170px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.image2{
    width: 300px;
    height: 170px;
}
.youtubeText{
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0;
    text-align: center;
    vertical-align: middle;
    width: 90%;
    padding: 0 5%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 8px;
}

.dots {
    display: flex;
    justify-content: center;
    gap: 6px;
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.dot {
    width: 32px;
    height: 4px;
    border-radius: 2px;
    background: #e2e4e9;
}

.dot.active {
    background: #2d3139;
}

.wrapperArrow {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    height: 40px;
    width: 40px;
    padding: 0 !important;
    top: calc(50% - 20px);
    z-index: 1;
    box-shadow: 0px 8px 32px 0px #0000001a;

    fill: #0b0c0e;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
        fill: #f3cb67;
    }
}
.wrapperArrow3{
    top: calc(50% - 35px);
}
.leftPos {
    transform: rotate(180deg);
    left: -19px;
}

.rightPos {
    right: -20px;
}
.arrowImg {
    height: 20px;
}
.rotateImg {
    transform: rotate(180deg);
}

.block{
    display: flex;
    gap: 20px;
    margin-top: 20px;
    align-items: flex-start;
}
.block2{
    width: calc(50% - 10px);
}
.youtubeSliderBlock{
    width: 400px;
    height: 170px;
}
.youtubeBlock{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F8F8F8;
    border-radius: 16px;
    height: 315px;
    flex-direction: column;
}
.linkYoutube{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 42px;
    margin-bottom: -25px;
    cursor: pointer;
}

@media screen and (max-width: 1280px) {
    .sliderWrapper {
        width: 100%;
    }
    .block2{
        width: calc(50% - 10px);
    }
    .slider {
        width: 100%;
        height: 340px;
        img {
            width: 100%;
            height: 100%;
        }
    }
}

@media screen and (max-width: 1024px) {
    .youtubeSliderBlock{
        width: 300px;
        height: 170px;
    }
}

@media screen and (max-width: 960px) {
    .slider {
        height: 300px;
    }
}

@media screen and (max-width: 768px) {
    ._{
        width: 100%;
    }
    .image{
        border-radius: 0;
    }
    .imageBlock{
        border-radius: 0;
    }
    .sliderWrapper {
        min-width: 100vw;
    }
    .navigate {
        bottom: 14px;
        right: 16px;
        > div {
            height: 30px;
            width: 30px;
        }
    }
    .navigateItem {
        height: 36px;
        width: 36px;
    }
    .arrowImg {
        height: 16px;
    }
    .slider {
        height: 260px;
        border-radius: 0;
    }
    .mainBanner {
        border-radius: 0;
    }
    .wrapper {
        justify-content: center;
    }
    .slider {
        border-radius: 0;
        > div {
            &:nth-child(2) {
                border-radius: 0px;
                > div {
                    > div {
                        > div {
                            > a {
                                > img {
                                    border-radius: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .dots {
        gap: 4px;
        li {
            width: 16px;
            margin: 0 !important;
        }
    }

    .dot {
        width: 16px;
        height: 2px;
    }
    .wrapperArrow {
        display: none;
    }
    .block{
        flex-direction: column;
    }
    .youtubeBlock{
        width: 100%;
        border-radius: 0;
    }
    .wrapperArrow3{
        display: flex;
    }
    .image2{
        border-radius: 16px;
    }
}

@media screen and (max-width: 1280px) {
    .slider {
        width: 100%;
        position: relative;
        border-radius: 16px;
        height: auto;
        > div {
            &:nth-child(2) {
                height: auto;
                > div {
                    height: auto;
                    > div {
                        > div {
                        }
                    }
                }
            }
        }
    }
}

