.pageContainer {
    display: flex;
    flex-direction: column;
}

.description {
    margin-top: 16px;
}

.content {
    display: flex;
    gap: 38px;

    >div {
        width: 50%;
    }
}


.name {
    font-family: var(--title-txt);
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0.02em;
    color: #0b0c0e;
    margin-bottom: 9px;
}

.descriptionValue {
    font-family: var(--content-txt);
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    color: var(--grey-400);
    margin-top: 12px;
    display: flex;

    img {
        margin-right: 14px;
    }

    span {
        margin-left: 4px;
        font-weight: 600;
        color: var(--black-400);
    }
}

.discountWrapper {
    width: 100%;
}

.contDis {
    display: flex;
    align-items: center;
    gap: 8px;
}

.otherTag {
    border-radius: 4px;
    padding: 1px 8px;
}

.labels {
    display: flex;
    align-items: center;
    font-family: var(--content-txt);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-top: 12px;

    div {
        margin-right: 8px;
        white-space: nowrap;
    }
}

.sallary {
    border: 1px solid #0090ff;
    color: #0090ff;
    border-radius: 4px;
    padding: 1px 8px;
}

.newItem {
    border: 1px solid #f76b15;
    color: #f76b15;
    border-radius: 4px;
    padding: 1px 8px;
}

.hit {
    border: 1px solid #46a758;
    color: #46a758;
    min-width: 72px;
    border-radius: 4px;
    padding: 1px 8px;
}

.disWrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.pricesWrapper {
    display: flex;
    font-family: var(--content-txt);
    align-items: start;
    flex-direction: column;
    margin-top: 25px;
    border: 1px solid #e2e4e9;
    // padding: 10px 24px 24px 24px;
    padding: 24px;
    border-radius: 12px;
}

.total_price {
    line-height: 32px;
    color: var(--black-400);
    font-size: 28px;
    font-weight: 700;
    margin-top: 4px;
    letter-spacing: 1.4px;
}

.price {
    color: #80899c;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-decoration: line-through;
}

.salleryPrice {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: var(--black-400);
    background: #ecd06c;
    border-radius: 4px;
    padding: 1px 6px;
}

.activeBlock {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 21px;

    >div {
        &:first-child {
            width: calc(100% - 218px);
        }
    }
}

.labelActive {
    margin-left: 12px;
    height: 44px;
    width: 44px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f8f8f8;
    transition: all 0.3s ease-in-out;
    position: relative;

    span {
        position: absolute;
        font-size: 13px;
        font-weight: 300;
        top: 35px;
        left: 107%;
        color: #000;
        background: #aeaeae;
        box-shadow: 0px 0px 8px 1px #aeaeae;
        border-radius: 4px;
        padding: 4px 8px;
        opacity: 0;
        transition: opacity 0.3s ease;
        z-index: 0;
    }

    &:hover {
        cursor: pointer;
        background: #e2e4e9;

        span {
            opacity: 1;
            z-index: 1;
        }
    }
}

.compareActive {
    background: #ecd06c;
}

.inHave {
    line-height: 20px;
    display: flex;
    align-items: start;
    margin-left: auto;
    letter-spacing: 0.4px;

    p {
        font-size: 16px;
        font-weight: 500;
        margin: 2px 0 0 0;
    }
}


.acceptImg {
    background: #d7eecf;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
}

.select {
    display: flex;
    width: 100%;
    padding: 6px;
    border-radius: 8px;
    background: #eff1f6;
    justify-content: flex-start;
    margin-top: 35px;
    gap: 17px;
}

.detailsContent {
    margin-top: 20px;
    padding: 20px;
}
.detailsContentText{
    font-size: 16px;
    font-weight: 400;
    color: #434956;
}

.characteristics {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    @media screen and (max-width: 1280px) {
        grid-template-columns: repeat(1, 1fr)
    }
}

.characteristicItem {
    padding: 15px;

}

.delivery {
    font-size: 16px;
    color: #555;
}

.option {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    padding: 10px 17px;
    border-radius: 7px;
    color: #596173;
    transition: all 0.3s ease-in-out;
    letter-spacing: 0.2px;
    text-wrap: nowrap;

    &:hover {
        cursor: pointer;
        background: #e2dfdf;
    }

    span {
        background: #f3cb67;
        margin-left: 6px;
        border-radius: 4px;
        padding: 1px 5px;
    }
}

.activeOption {
    background: #fff;
    color: var(--black-400);
}

.video {
    margin-top: 40px;

    iframe {
        border-radius: 16px;
        width: 100%;
        height: 345px;
    }
}

.mobileContent {
    display: none;

    iframe {
        height: 528px;
    }
}

.ratingWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 22px;

    div {
        height: 30px;
        width: 30px;
        margin-bottom: -8px;
    }

    p {
        color: #0b0c0e;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        margin: 0;
        margin-right: 12px;
    }

    span {
        margin-top: 2px;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        color: #596173;
        border-bottom: 1px dashed #596173;
        cursor: pointer;
    }
}

.videoWrapper {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    border-radius: 8px;
    background: rgba(0, 0, 0, 0.05);

    .videoPlayer {
        width: 100%;
        max-width: 600px; // Ограничение по ширине для больших экранов
        height: 405px;
        border-radius: 8px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    }

    .playIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 64px;
        height: 64px;
        background: rgba(128, 128, 128, 0.7);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;

        &.hidden {
            display: none;
        }
    }
}

@media screen and (max-width: 1280px) {
    .videoWrapper {
        margin-top: 15px;

        .videoPlayer {
            width: 100%;
            max-width: none; // Убираем ограничение по ширине
        }

        .playIcon {
            width: 48px;
            height: 48px;
        }
    }
}

@media screen and (max-width: 1280px) {
    .content {
        display: none;
    }

    .labels {
        margin-top: 32px;
        margin-bottom: 8px;
    }

    .name {
        margin-bottom: 14px;
    }

    .inHave {
        font-size: 18px;
    }

    // .select {
    //    justify-content: space-evenly;
    // }
    .mobileContent {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .headItems {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .descriptionValue {
                font-size: 14px;
                color: #666;

                span {
                    font-weight: bold;
                }
            }

        }

        .inHave {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-left: 0;

            .acceptImg img {
                width: 20px;
                height: 20px;
            }

            p {
                margin: 0;
                //color: green;
                font-weight: bold;
            }
        }

        .pricesWrapper {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .discountWrapper {
                .contDis {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .price {
                        text-decoration: line-through;
                        color: #999;
                    }

                    .salleryPrice {
                        //color: #e53935;
                        font-weight: bold;
                        margin-left: 10px;
                    }
                }
            }

            .total_price {
                font-size: 20px;
                font-weight: bold;
                color: #000;
            }

            .orderButton {
                width: 100%;
            }

        }

        .documents {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .document {
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: rgba(226, 228, 233, 0.5);
                }

                .downloadText,
                span {
                    color: black;
                    text-decoration: none;
                }

                .documentLeft {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    span {
                        font-size: 14px;
                        color: #333;
                    }
                }

                a {
                    color: #000;
                    text-decoration: none;
                }
            }
        }

        .video {
            iframe {
                width: 99%;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .mobileContent {
        iframe {
            height: 400px;
        }
    }

    .inHave {
        margin-top: 10px;
    }
}

@media screen and (max-width: 768px) {
    .labels {
        margin-top: 22px;
    }

    .name {
        font-size: 24px;
        line-height: 32px;
        margin-top: 8px;
        margin-bottom: 7px;
    }

    .select {
        overflow-x: scroll;
        scrollbar-width: none;
    }

    .video {
        iframe {
            height: 260px;
        }
    }

    .ratingWrapper {
        margin-bottom: 5px;

        p {
            font-size: 18px;
        }

        span {
            font-size: 16px;
        }
    }

    .descriptionValue {
        font-size: 16px;
        font-weight: 400;
        margin-top: 12px;
        line-height: 22px;

        span {
            font-weight: 600;
        }
    }

    .select {
        margin-top: 25px;
    }

    .video {
        margin-top: 30px;
    }

    .activeBlock {
        margin-top: 12px;
    }

    .pricesWrapper {
        padding: 15px;
        margin-top: 21px;
    }

    .total_price {
        font-size: 24px;
    }

    .inHave {
        margin-top: -9px;

        p {
            font-size: 14px;
        }
    }
}

@media screen and (max-width: 510px) {
    .video {
        iframe {
            height: 193px;
        }
    }
}

@media screen and (max-width: 375px) {
    .activeBlock {
        >div {
            &:first-child {
                width: auto;
            }
        }
    }
}

.documents {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.document {
    color: black;
    text-decoration: none;
    align-items: center;
    gap: 0.5rem;
    width: 90%;
    border-radius: 16px;
    border: 1px solid #E2E4E9;
    background: rgba(255, 255, 255, 0.10);
    height: 48px;
    display: flex;
    justify-content: space-between;
    padding-right: 24px;
    padding-left: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: rgba(226, 228, 233, 0.5);
    }

    .documentLeft {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            text-decoration: none;
            margin-left: 12px;
        }
    }

    a {
        color: black;
    }
}

.descriptionValue {
    font-size: 16px;
    margin-top: 12px;

    span {
        margin-left: 8px;
        font-weight: 600;
    }
}

.orderButton {
    margin-top: 16px;
    padding: 12px 24px;
    width: 50%;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: #000;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.orderButton:hover {
    background-color: #333;
}

.policyText {
    margin-top: 12px;
    font-size: 12px;
    margin-bottom: 0;
    color: #80899c;
}

.policyLink {
    color: #000;
    text-decoration: underline;
    cursor: pointer;
}