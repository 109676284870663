.title{
    text-align: center;
}
.block{
    max-width: 1280px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.cont{
    width: 90dvw;
    max-width: 1280px;
}
._{
    display: flex;
    align-items: center;
    justify-content: center;
}
.table{

}

@media screen and (max-width: 600px) {
    .title{
        font-size: 20px;
    }
    .table{
        font-size: 12px;
    }
}
