@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

:root {
   --title-txt: 'Noto Sans', sans-serif;
   --content-txt: 'Inter', sans-serif;
   --grey-100: #f8f8f8;
   --grey-200: #e2e4e9;
   --grey-300: #7f7d83;
   --grey-400: #596173;
   --grey-500: #434956;
   --grey-600: #eff1f6;
   --black-100: #0a090b12;
   --black-200: #2d3139;
   --black-300: #4f4d55;
   --black-400: #0b0c0e;
   --blue-100: #80899c;
   --blue-200: #8c94a6;
   --orange-100: #d36f1d;
}

* {
   scroll-behavior: smooth;
}

BODY {
   margin: 0;
   padding: 0;
   font-family: Inter;
   zoom: 1;
   overflow-x: hidden;
}

a {
   text-decoration: none;
   color: #000;
}

.container {
   width: 90dvw;
   max-width: 1280px;
   margin: 0 auto;
}

.horizontal-slider {
   width: 100%;
   height: 50px;
}

.horizontal-slider .example-track {
   top: 20px;
   height: 10px;
   border-radius: 8px;
}

.example-track.example-track-0 {
   background: #f8f8f8;
}

.example-track.example-track-1 {
   background: #ecd06c;
}

.example-track.example-track-2 {
   background: #f8f8f8;
}

.example-thumb {
   font-size: 0px;
   background-color: #fff;
   color: #fff;
   cursor: pointer;
   box-sizing: border-box;
   height: 16px;
   width: 16px;
   border-radius: 50%;
   top: 17px;
   box-shadow: rgba(100, 100, 111, 0.6) 0px 7px 29px 0px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

.openBonusMenu-enter {
   transform: translateY(100%);
   opacity: 0;
}

.openBonusMenu-enter-active {
   transform: translateY(0);
   bottom: 70px;
   opacity: 1;
   transition: transform 300ms ease-in-out;
}

.openBonusMenu-exit {
   transform: translateY(0);
}

.openBonusMenu-exit-active {
   transform: translateY(100%);
   transition: transform 400ms ease;
}

.openBonusFon-enter {
   opacity: 0;
}

.openBonusFon-enter-active {
   opacity: 1;
   transition: opacity 500ms;
}

.openBonusFon-exit {
   opacity: 1;
}

.openBonusFon-exit-active {
   opacity: 0;
   transition: opacity 500ms;
}
.block-enter {
   opacity: 0;
}
.block-enter-active {
   opacity: 1;
   transition: opacity 300ms;
}
.block-exit {
   opacity: 1;
}
.block-exit-active {
   opacity: 0;
   transition: opacity 300ms;
}
.xl65{
   text-align: center;
}
.xl72,.xl69,.xl67,.xl76,.xl77,.xl74,.xl71,.xl70,.xl66{
   padding: 10px;
}
@media screen and (max-width: 600px) {
   .xl65,.xl63{
      max-width: 30px;
      font-size: 10px;
      max-height: fit-content;
      height: 10px;
   }
   .xl72,.xl69,.xl67,.xl69,.xl76,.xl77,.xl74,.xl71,.xl70,.xl66{
      padding: 5px;
      max-height: fit-content;
   }
}