.about {
    padding: 40px;
    padding-bottom: 0;
    margin: 0 auto;
    margin-top: 16px;
    margin-bottom: 14px;
    border-radius: 16px;
    border: 1px solid #E2E4E9;
    width: 100%;
    box-sizing: border-box;

    //box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    @media screen and (max-width: 1280px) {
        padding: 0;
        border: none;
    }

    .title {
        font-size: 32px;
        margin: 0;
        font-weight: 700;
        letter-spacing: 0.02em;
    }

    .content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 40px;
        align-items: start;

        @media (max-width: 1280px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: left;
        }

        .text {
            align-self: baseline;
        }
    }

    .text {
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        color: #596173;

        p {
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            color: #596173;
            @media (max-width: 1280px) {
                font-size: 16px;
                line-height: 26px;
            }
            &:last-child {
                margin-bottom: 20px;
            }

        }
        @media (max-width: 1280px) {
            font-size: 16px;
            line-height: 26px;
        }
    }

    .image {
        margin-bottom: 10px;
        @media screen and (max-width:1280px) {
            max-height: 30vh;
            width: auto;
            margin-top: 20px;
            display: none;
        }

        img {
            width: 100%;
            height: auto;
            border-radius: 8px;

            @media screen and (max-width:1280px) {
                max-height: 30vh;
                width: auto;
                margin-bottom: 20px;
            }
        }

    }
}