.image-viewer {
    width: 100%;
    margin: 0 auto;
    margin-top: 60px;

    &__main {
        position: relative;
        width: 100%;
        aspect-ratio: 16/9;
        background-color: #f5f5f5;
        margin-bottom: 1rem;
        overflow: hidden;
        border-radius: 16px;

        @media (max-width: 768px) {
            aspect-ratio: 4/3;
        }

        &__description {
            position: absolute;
            background: rgba(0,0,0,.7);
            color: #FFFFFF;
            width: calc(100% - 10px);
            padding-left: 10px;
        }
    }

    &__media {
        width: 100%;
        height: 100%;
        object-fit: contain;
        display: block;
    }

    &__info {
        position: absolute;
        top: 1rem;
        right: 1rem;
        background: rgba(0, 0, 0, 0.8);
        color: white;
        padding: 1.5rem;
        border-radius: 8px;
        max-width: 400px;
        transition: all 0.3s ease;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__blockF {
            height: 90%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-left: 10px;
            margin-right: 10px;
            justify-content: center;

            h2 {
                margin: 0 0 1rem;
                font-size: 1.5rem;
            }

            p {
                margin: 0 0 1rem;
                font-size: 1rem;
                line-height: 1.5;
            }
        }


        @media (max-width: 1280px) {
            top: 0;
            right: 0;

            max-width: 100%;
            width: 100%;
            margin: 0;
            height: 100%;
            padding: 0;

            h2 {
                margin: 0 0 0.5rem;
                margin-left: 12vw;
                //margin-top: 10vw;
                font-size: 1rem;
            }

            p {
                margin: 0 0 0.5rem;
                margin-left: 12vw;
                margin-right: 12vw;
                font-size: 11px;
                line-height: 1;
            }
        }
        @media (max-width: 500px) {
            p {
                margin-left: 2vw;
                margin-right: 2vw;
            }
            h2 {
                margin: 0 0 0rem;
                margin-left: 2vw;
                margin-top: 2vw;
                font-size: 1rem;
            }
            &__blockF {
                gap: 10px;
            }
        }
    }

    &__collapsed-info {
        position: absolute;
        top: 1rem;
        right: 1rem;
        background: rgba(0, 0, 0, 0.7);
        color: white;
        padding: 1.5rem;
        border-radius: 8px;
        max-width: 400px;
        transition: all 0.3s ease;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;

        @media (max-width: 768px) {
            top: 0;
            right: 0;
            box-sizing: border-box;
            margin: auto;
            padding: 0.5rem;
            gap: 7px;
        }
    }

    .iconContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        cursor: pointer;
        transition: transform 0.3s;

        &.iconRotated {
            transform: rotate(180deg);
        }
    }

    .icon {
        width: 24px;
        height: 24px;
        transition: transform 0.3s;

        @media (max-width: 768px) {
            width: 12px;
            height: 12px;

        }
    }

    &__documents {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-bottom: 1rem;

        @media (max-width: 1280px) {
            gap: 0.1rem;
            margin-bottom: 0.1rem;
            margin-left: 12vw;
            margin-right: 12vw;
            width: calc(100% - 24vw);
        }
        @media (max-width: 500px) {
            width: calc(100% - 10px);
            margin-left: 10px;
            margin-right: 0;
        }
    }

    &__document {
        color: white;
        text-decoration: none;
        align-items: center;
        gap: 0.5rem;
        width: 90%;
        border-radius: 16px;
        background: rgba(255, 255, 255, 0.10);
        height: 48px;
        display: flex;
        justify-content: space-between;
        padding-right: 16px;
        padding-left: 16px;
        cursor: pointer;

        .download-text {
            color: white;
            text-decoration: none;
        }

        a {
            color: white;
        }

        &:hover {
            background: rgba(255, 255, 255, 0.15);
        }

        @media (max-width: 1280px) {
            gap: 0.1rem;
            height: 21px;
            padding-right: 12px;
            padding-left: 12px;
            width: calc(100% - 24px);
            font-size: 12px;

            svg {
                width: 12px;
                height: 12px;
            }
        }
        @media (max-width: 500px) {
            width: calc(100% - 24px);
            font-size: 11px;
        }

    }

    &__hide-info {
        background: transparent;
        border: transparent;
        color: white;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        cursor: pointer;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        transition: all 0.3s ease;

        &:hover {
            background: rgba(255, 255, 255, 0.1);
        }

        @media (max-width: 768px) {
            gap: 7px;
            padding: 0.2rem 0.5rem;
            font-size: 11px;
        }
    }

    /*&__thumbnails {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 110px));
        gap: 1rem;
        padding: 1rem 0;

        @media (max-width: 768px) {
            grid-template-columns: repeat(auto-fit, minmax(80px, 110px));
            gap: 0.5rem;
        }
    }

    &__thumbnail {
        position: relative;
        aspect-ratio: 1;
        cursor: pointer;
        border-radius: 4px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: opacity 0.2s;
        }

        &--active {
            img {
                opacity: 0.7;
            }
        }
    }*/
    .image-viewer__thumbnails {
        display: flex;
        /* Располагаем миниатюры в одну строку */
        gap: 0.5rem;
        /* Отступ между миниатюрами */
        overflow-x: auto;
        /* Горизонтальная прокрутка */
        -webkit-overflow-scrolling: touch;
        /* Плавная прокрутка на мобильных устройствах */
        padding-bottom: 10px;
        /* Отступ для прокрутки */
        scroll-behavior: smooth;
        /* Плавная прокрутка */
    }

    .image-viewer__thumbnail {
        position: relative;
        flex: 0 0 auto;
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f0f0f0;
        border: 1px solid #ddd;
        border-radius: 5px;
        overflow: hidden;
        cursor: pointer;
        transition: transform 0.3s ease;

        &:hover {
            transform: scale(1.1);
        }
    }

    .image-viewer__thumbnail img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .image-viewer__thumbnail--active {
        border: 2px solid #007bff;
        /* Выделение активной миниатюры */
    }

    &__video-indicator {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        border-style: solid;
        border-width: 0;
        border-color: transparent transparent transparent white;

        &::after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 0 8px 16px;
            border-color: transparent transparent transparent #ffffff;
            margin-left: 2px;
        }
    }
}