.mainImage {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 528px;

   img {
      height: auto;
      max-height: 100%;
      margin-bottom: 18px;
      border-radius: 16px;
      width: 100%;
      max-width: min-content;
   }
}

.hideBitImg {
   display: none;
}

.activeBigImg {
   display: block;
}

.smallSlider {
   display: flex;
   justify-content: space-between;
   padding: 0 10px;
   height: 100px;
   margin-top: 2px;
   align-items: center;
   > img {
      cursor: pointer;
      height: 20px;
   }
}

.smallSliderItem {
   display: flex;
   justify-content: center;
   align-items: center;
   background: #fff;
   border-radius: 8px;
   padding: 1px;
   cursor: pointer;
   height: 90px;
   width: 90px;
   overflow: hidden;
   padding: 3px;
   background: #f8f8f8;

   img {
      border-radius: 8px;
      height: 90px;
      width: 100%;
   }
}

.activeSmallSlide {
   border: 1px solid #000;
   overflow: hidden;
}

.rightArrow {
   cursor: pointer;
}

.leftArrow {
   transform: rotate(180deg);
   cursor: pointer;
}

// @media screen and (max-width: 1280px) {
//    .mainImage {
//       img {
//          height: 300px;
//          width: auto;
//       }
//    }
// }

@media screen and (max-width: 1280px) {
   .mainImage {
             img {
                height: 100%;
                width: auto;
             }
          }
}

@media screen and (max-width: 768px) {
   .mainImage {
      height: 285px;
   }
   .smallSliderItem {
      height: 70px;
      width: 70px;
      img {
         height: 70px;
      }
   }
}
@media screen and (max-width: 450px) {
   .mainImage {
      height: 200px;
   }
}
